import React from "react";
import { graphql } from "gatsby";
import { Grid, List, Table } from "semantic-ui-react";
import Helmet from "react-helmet";
import {
  PageLayout,
} from "@fishrmn/fishrmn-components";

import Layout from "../components/Layout";

export default class extends React.PureComponent {
  render() {
    const businessData = this.props.data.allFisherman.edges[0].node;
    const data = { ...businessData };

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Privacy Policy</title>
          <meta name="description" content="" />
        </Helmet>
        <PageLayout
          hero={null}
        >
          <Grid
            stackable
            className="component-section-container privacy-policy"
            verticalAlign={"middle"}
            textAlign={"left"}
            centered
          >
            <Grid.Column width={14}>
              <h3>DATA	PRIVACY	NOTICE</h3>

              <List bulleted>
                <List.Item>
                  <span className="underlined">Personal  Information We  Collect And Use</span>
                  </List.Item>

                  <List.Item>
                  <span className="underlined">How We  May Use Your  Information</span>
                  </List.Item>

                  <List.Item>
                  <span className="underlined">Cookies And Similar Technologies</span>
                    </List.Item>

                    <List.Item>
                  <span className="underlined">How We Share Personal  Information</span>
                    </List.Item>

                    <List.Item>
                  <span className="underlined">How We  Protect Your  Personal  Information</span>
                    </List.Item>

                    <List.Item>
                  <span className="underlined">California  Privacy Notice</span>
                    </List.Item>

                    <List.Item>
                  <span className="underlined">Changes To  This  Notice</span>
                    </List.Item>

                    <List.Item>
                  <span className="underlined">Contact Us</span>
                    </List.Item>
              </List>

              <p>
                This	is	the	Data	Privacy	Notice	("Privacy	Notice")	of	Yogurtland	Franchising,	Inc.	and	its	affiliate	and	
                subsidiary	entities	("Egg	N	Bird"	or	the	"Company").	We	maintain	a	website	and	applications	(the	
                "sites")	to	provide	our	products	to	you.	These	sites	may	ask	for	and	collect	your	personal	information	in	
                order	to	provide	you	with	our	products,	enhance	your	experience,	and	provide	you	with	other	relevant	
                information	about	our	offerings.	This	Privacy	Notice	applies	to	activities	the	Company	engages	in	on	its	
                sites	and	activities	that	are	offline	or	unrelated	to	its	sites.	We	are	providing	this	notice	to	explain	our	
                information	practices	and	the	choices	you	can	make	about	the	way	your	information	is	collected	and	
                used.
              </p>
              <p>
                This	Privacy	Notice	sets	forth	the	principles	that	govern	our	treatment	of	personal	information.	We	
                expect	all	of	our	employees	and	those	with	whom	we	share	personal	information	to	adhere	to	this	
                Privacy	Notice.
              </p>
              <p>
                The	Company	is	committed	to	protecting	the	information	that	our	customers,	employees,	suppliers,	and	
                others	have	entrusted	to	us.
              </p>
              <p>
                This	Privacy	Notice	applies	to	all	personal	information	collected	by	the	Company,	relating	to	all	
                customers,	employees,	suppliers,	and	others	who	do	business	with	us.
              </p>
              <h6>PERSONAL	INFORMATION	WE	COLLECT	AND	USE</h6>
              <h3 className="no-margin">
                <strong>Personal	Information	You	Provide</strong>
              </h3>
              <p>
                We	only	collect	personal	information	that	you	provide	to	us,	or	that	we	are	authorized	to	obtain	by	you	
                or	by	law.	The	types	of	personal	information	we	collect	from	you	will	depend	on	how	you	are	interacting	
                with	us	using	our	sites	and	products.	For	example,	we	may	collect	different	information	from	you	when	
                you	purchase	products	at	an	Egg	N	Bird	location,	or	if	you	register	for	a	Rewards	program	or	ask	us	a	
                question.	Depending	on	how	you	interact	with	our	sites,	we	may	have	collected	the	following	categories	
                of	personal	information	from	you	within	the	past	12	months:
              </p>

              <List bulleted>
                <List.Item>
                  When	you	give	feedback	or	ask	questions:	We	may	collect	your	name	and	feedback	from	you	
                  directly	or	from	social	media;
                </List.Item>
                <List.Item>
                  When	you	place	an	order	or	participate	in	a	promotion:	We	may	collect	your	name;	payment	
                  card	number,	expiration	date,	and	CVV	from	you;
                </List.Item>
                <List.Item>
                When	you	place	a	remote	order:	We	may	collect	your	name	and	order	information	from	third	
party	service	providers	chosen	by	you	(e.g.,	Doordash,	Uber	Eats,	GrubHub)	as	needed	to	fulfill	
your	order;
                </List.Item>
                <List.Item>
                When	you	enroll	in	and	use	Rewards:	We	may	collect	your	name;	date	of	birth;	Rewards	number;	
address;	phone	number;	email	address;	purchase	history	information;	payment	card	number,	
expiration	date,	and	CVV	from	you;
                </List.Item>

                <List.Item>
                If	you	ask	us	to	send	you	marketing	information	and	special	offers:	We	may	collect	your	name;	
date	of	birth;	email	address;	and	phone	number	from	you;
                  </List.Item>

                  <List.Item>
                  When	you	partner	with	the	Company	for	fundraising	activities:	We	may	collect	your	name;	the	
name	of	your	organization;	tax	ID;	mailing	address;	phone	number;	and	email	address	from	you;
                  </List.Item>

                  <List.Item>
                  To	operate,	evaluate,	and	improve	our	business,	including	improving	and	personalizing	the	
experience	for	you	and	others;
                    </List.Item>

                    <List.Item>
                    Using	Data	Analytics	to	audit,	research,	and	conduct	analysis	in	order	to	maintain	and	improve	
our	products	and	to	protect	people	who	use	our	sites;
                    </List.Item>

                    <List.Item>
                    For	safety	and	security,	by	making	sure	third	parties	protect	your	information,	monitoring	the	
technical	functioning	and	security	of	our	network;
                    </List.Item>

                    <List.Item>
                    For	legal	and	compliance,	including	complying	with	applicable	laws,	regulations,	and	legal	
obligations;	and
                    </List.Item>

                    <List.Item>
                    To	protect	the	rights	or	property	of	the	Company,	its	employees,	and	people	who	use	its	sites.
                    </List.Item>
                    <p>
                    We	do	not	sell	or	disclose	any	personal	information	from	individuals	under	18	years	of	age	to	third	
parties.
                    </p>
              </List>

              <h6>HOW	WE	MAY	USE	YOUR	INFORMATION</h6>

              <p>
                <strong>
                Your	privacy	is	important	to	us.	We	collect	and	use	information	we	need	to	do	our	business,	
including	providing	you	with	the	products	and	experiences	you	expect	from	us.	When	we	collect	
and	use	your	information,	we	take	your	privacy	and	security	very	seriously.
                </strong>
              </p>

              <p>
              We	collect	personal	information	to	provide	products	and	benefits	to	you.	We	will	use	the	information	
provided	by	you	or	on	your	behalf	to	provide	our	products,	such	as	our	in-store	products	and	Rewards	
benefits.	Specifically,	we	have	used	your	personal	information	for	the	following	purposes	within	the	
twelve	(12)	months	preceding	the	date	of	this	Notice:
              </p>

              <Table size="small">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={6}>Category</Table.HeaderCell>
                    <Table.HeaderCell >Types of Information Collected</Table.HeaderCell>
                    <Table.HeaderCell>Purpose for Collection</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>A. Identifiers.</Table.Cell>
                    <Table.Cell>
                      Real name, postal address, unique personal
                      identifier, online identifier, Internet
                      Protocol address, email address, or other
                      similar identifiers.
                    </Table.Cell>
                    <Table.Cell>
                    <List items={['Feedback', 'Orders', 'Rewards', 'Marketing', 'Fundraising']} />
                    </Table.Cell>
                  </Table.Row>
                 
                  <Table.Row>
                    <Table.Cell>
                      B. Personal information
                      categories listed in the
                      California Customer Records
                      statute (Cal. Civ. Code §
                      1798.80(e)).
                    </Table.Cell>
                    <Table.Cell>
                      <p>
                        Name, address, telephone number, credit
                        card number, debit card number, or any
                        other payment information.
                      </p>
                      <p>
                        Some personal information included in this
                        category may overlap with other
                        categories.
                      </p>
                    </Table.Cell>
                    <Table.Cell>
                      <List items={['Feedback', 'Orders', 'Rewards', 'Marketing', 'Fundraising']} />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      C. Protected classification
                      characteristics under California
                      or federal law
                    </Table.Cell>
                    <Table.Cell>
                      Age, date of birth.
                    </Table.Cell>
                    <Table.Cell>
                      Rewards
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                    D. Internet or other similar
                    network activity
                    </Table.Cell>
                    <Table.Cell>
                      Internet Protocol (IP) address, network
                      location, time/date stamp, referring site
                      details, browser type, referring/exit pages,
                      operating system, and/or clickstream data
                    </Table.Cell>
                    <Table.Cell>
                      <List items={['Rewards', 'Marketing', 'Analytics', 'Safety/Security']} />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

              <h6>COOKIES	AND	SIMILAR	TECHNOLOGIES</h6>
              <p>
              A	"cookie"	is	a	small	text	file	that	websites	send	to	your	computer	or	device	other	Internet-connected	
device	to	identify	your	browser	or	to	store	information	or	settings	in	the	browser.	A	"web	beacon",	also	
known	as	an	Internet	tag,	pixel	tag	or	clear	GIF,	links	web	pages	to	web	servers	and	their	cookies	and	
may	be	used	to	transmit	information	collected	through	cookies	back	to	a	web	server.
              </p>
              <p>
              Our	sites	use	cookies,	web	beacons,	java	scripts,	and	similar	technologies	to	automatically	collect	certain	
information	about	your	activity	relating	to	your	visit	to	our	sites.	These	technologies	collect	your	
Internet	Protocol	(IP)	address,	network	location,	time/date	stamp,	referring	site	details,	browser	type,	
referring/exit	pages,	operating	system,	and/or	clickstream	data.	We	use	this	data	to	analyze	trends,	
administer	our	sites,	track	users'	movements	through	our	sites,	and	gather	demographic	information	
about	our	guests	as	a	whole.	We	may	receive	reports	based	on	these	technologies	on	an	aggregated	basis
              </p>
              <h4>
                <strong>Behavioral	Tracking/Third	Party	Cookies</strong>
              </h4>
              <p>We	use	third-party	analytics	services	on	our	sites,	including	Google	Analytics.	To	learn	more	about	
Google	Analytics,	visit:	https://policies.google.com/technologies/partner-sites.</p>
              <p>
              Your	Internet	browser	may	let	you	restrict	or	disable	certain	types	of	cookies.	Without	cookies,	you	may	
not	be	able	to	use	all	of	the	options	on	our	sites.
              </p>
              <p>
              Some	web	browsers	allow	you	to	turn	on	Do	Not	Track	("DNT"),	which	sends	signals	to	websites	you	
visit,	telling	those	sites	that	you	do	not	want	your	online	activities	to	be	tracked.	Our	sites	currently	are	
not	designed	to	respond	to	DNT	signals	received	from	web	browsers.
              </p>
              <p>
              We	may	also	use	JavaScript	to	help	our	sites,	especially	pictures,	function	better.	You	can	turn	JavaScript	
on	or	off	in	your	browser	functions,	but	if	you	turn	it	off,	you	may	not	be	able	to	use	all	of	the	options	on	
our	sites.
              </p>
              <p>
              Sometimes	we	use	technology	to	collect	the	information	that	your	browser	uses	to	auto-fill.	We	use	this	
to	tell	you	if	there	is	a	mistake	in	registration	or	if	the	registration	cannot	be	submitted.	You	can	stop	this	
technology	by	turning	it	off	in	your	browser,	and	you	will	still	be	able	to	use	all	ofthe	options	on	our	
sites.
              </p>
              <h6>HOW	WE	SHARE	PERSONAL	INFORMATION</h6>
              <p>We	may	share	information	about	you	to	third	parties	as	indicated	below:</p>
              <p>
                Affiliates	/	Franchisees:	We	may	share	your	personal	information	with	our	affiliates,	franchisees, and	
                subsidiaries	if	we	need	to.	If	we	share	your	information,	we	will	share	only the information	that is	
                necessary	and	we	will	take	reasonable	steps	to	make	sure	that	third	parties	take	prudent	steps	to	protect	
your	information.
              </p>
              <p>
              Service	Providers,	Suppliers,	Agents,	and	Business	Partners:	Other	companies	sometimes	provide	certain	
services	to	us	or	on	our	behalf	(e.g.,	maintenance,	IT	support,	analysis,	development,	security).	Unless	we	
tell	you	differently	or	as	described	elsewhere	in	this	Privacy	Notice,	our	service	providers	are	not	
permitted	to	use	your	information	except	to	assist	us.
              </p>
              <p>
              Compliance	with	Legal	Obligations:	We	may	need	to	disclose	certain	information	to	auditors,	government	
authorities,	law	enforcement,	regulatory	agencies,	our	legal	counsel,	third	party	litigants	and	their	
counsel,	or	other	authorized	individuals	in	order	to	comply	with	laws	that	apply	to	us,	or	other	legal	
obligations	such	as	contractual	requirements.
              </p>
              <p>
              Changes	in	Business	Structure/Ownership:	We	may	disclose	or	transfer	your	personal	information	to	a	
third	party	in	the	event	of	any	reorganization,	merger,	sale,	joint	venture,	assignment,	transfer,	or	other	
disposition	of	all	or	any	portion	of	the	Company's	business,	assets,	or	ownership	interest	(including	any	
bankruptcy	or	similar	proceedings).
              </p>
              <p>
                <strong>We	have	not,	sold,	shared	or	monetized	any	categories	of	personal	information	within	the	past	12	
                months.</strong>
              </p>
              <p className="no-margin">
              Information	We	Disclose	for	a	Business	Purpose
              </p>
              <p>In	addition	to	the	purposes	described	in	this	notice,	we	may	use	personal	information	listed	below	for	
business	purposes.</p>


              <List bulleted>
                <List.Item>
                To	provide	you	with	products,	including	customer	service,	processing	orders	or	transactions,	
verifying	consumer	information,	and	processing	payments,	we	use	the	following	categories	of	
information:	identifiers,	personal	information	subject	to	Cal.	Civ.	Code	§	1798.80(e),	protected	
classifications,	and	online	activity
                  </List.Item>

                  <List.Item>
                  To	improve	our	website,	including	responding	to	inquiries	and	improving	functionality,	we	use	
the	following	categories	of	information:	identifiers	and	online	activity
                  </List.Item>

                  <List.Item>
                  • To	comply	with	your	requests,	including	sending	your	information	to	third	parties	if	you	ask	us	
to	do	so:	identifiers,	personal	information	subject	to	Cal.	Civ.	Code	§	1798.80(e),	protected	
classifications,	and	online	activity.
                    </List.Item>
              </List>

              <h6>HOW	WE	PROTECT	YOUR	PERSONAL	INFORMATION</h6>
              <p>
              We	strive	to	take	appropriate	security	measures	to	help	safeguard	your	personal	information	from	
unauthorized	access	and	disclosure.	For	example,	only	authorized	employees	are	allowed	to	access	
personal	information,	and	they	may	only	access	it	for	permitted	business	functions.	We	also	use	
technology	to	protect	your	information,	including	encrypting	sensitive	personal	information	that	is	
transferred	to	or	from	our	systems.
              </p>
              <p>
              While	we	cannot	guarantee	that	loss,	misuse,	or	alteration	will	never	occur,	we	use	reasonable	efforts	to	
prevent	it.	Please	keep	in	mind	that	no	method	of	storage	or	transmission	over	the	Internet	is	completely	
secure,	so	your	use	of	our	services	and	provision	of	information	to	us	is	at	your	own	risk.
              </p>
              <p>
              Our	sites	may	contain	links	to	other	sites	on	the	Internet	that	are	owned	and	operated	by	third	parties.	
The	information	practices	of	those	websites	linked	to	our	sites	are	not	covered	by	this	Privacy	Notice.	We	
are	not	responsible	for	the	privacy	policies	of	other	websites	to	which	our	sites	link.
              </p>
              <h6>CALIFORNIA	PRIVACY	NOTICE</h6>
              <h4><strong>Your	Rights	and	Choices</strong></h4>
              <p>For	all	personal	information	that	we	collect	about	you,	you	may	have	the	following	rights	or	choices	that	
we	will	accommodate	where	your	requests	meet	legal	and	regulatory	requirements	and	do	not	risk	
making	our	other	data	less	secure	or	changing	our	other	data.	You	may	also	designate	an	authorized	
agent	to	make	a	request	on	your	behalf.</p>

              <p>Access,	Erase:	You	may	have	the	right	to	know	what	information	is	being	collected	about	you,	to	access	
              your	personal	information,	to	receive	a	copy	of	your	information,	and	to	know	whether	your	personal	
              information	is	disclosed	and	to	whom.	You	also	may	request	that	we	erase	your	information.	If	you	have	
              questions	about	your	information	or	want	to	ask	us	to	erase	your	information,	please	contact	us	using	
              the	methods	in	the	Contact	Us	section	below.</p>

              <p>Data	Portability:	If	you	would	like	to	request	that	we	provide	a	copy	of	your	information	to	you,	please	
contact	us	using	the	methods	in	the	Contact	Us	section	below.
Non-Discrimination:	You	have	the	right	not	to	receive	discriminatory	treatment	by	the	Company	because	
you	exercise	your	privacy	rights.	If	you	have	any	questions,	please	contact	us	using	the	methods	in	the	
Contact	Us	section	below.</p>

              <p>How	to	Make	a	Request:	If	you	would	like	to	make	a	request,	please	use	the	Contact	Us	section	at	the	
              bottom	of	this	page.	Please	provide	your	name,	email	address,	and	Rewards	member	number.</p>

              <p>
              Responding	to	Requests:	Your	request	will	be	evaluated	to	determine	whether	the	requested	change	
meets	legal	regulatory	requirements	and	does	not	risk	making	our	other	data	less	secure	or	changing	
our	other	data.
              </p>

              <p>
              Verifying	Your	Identity:	To	protect	you	and	your	information,	we	may	ask	you	to	confirm	certain	
information	before	we	honor	your	request.	For	example,	if	you	make	a	request,	we	will	ask	you	to	
confirm	your	name,	email	address,	and/or	Rewards	member	number	to	verify	your	identity,	so	that	we	
can	help	protect	your	information.	If	you	designate	an	authorized	agent	to	make	a	request	on	your	
behalf,	we	may	require	you	to	verify	your	identity	and	provide	the	authorized	agent's	identity	and	
contact	information	to	us.
              </p>

              <h6>CHANGES	TO	THIS	NOTICE</h6>

              <p>
              We	reserve	the	right	to	modify	or	change	this	Privacy	Notice	at	any	time.	When	we	make	a	material	
change	to	this	Privacy	Notice,	we	will	inform	you	by	posting	a	prominent	notice	on	the	home	page	of	our	
website	or	changing	the	date	on	this	page	noting	when	the	Privacy	Notice	was	last	updated.
              </p>

              <h6>CONTACT	US</h6>

              <p>
              For	questions	regarding	this	Privacy	Notice	or	to	submit	any	of	the	requests	mentioned	above	relating	to	
your	personal	information,	contact	us	using	any	of	the	following	options:
              </p>

              <div>
                <em>Mail</em>
                <div>
                17799	Cartwright	Rd.,	Irvine,	CA	92614
                </div>
              </div>

              <div style={{margin:'5px 0'}}>
                <em>Email</em>
                <div>
                info@yogurt-land.com
                </div>
              </div>

              <div>
                <em>Telephone</em>  
                <div>
                  949-265-8000
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          businessName
        }
      }
    }
  }
`;
